import React, { useState, useRef, useEffect } from "react"
import { makeStyles } from "@material-ui/core/styles"
import {
  Card,
  CardMedia,
  CardActionArea,
  Button,
  Tooltip,
  ClickAwayListener,
  createMuiTheme,
  withStyles,
} from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  media: {
    width: "100px",
    height: "100px",
    margin: "0 auto",
    background: "transparent",
    pointerEvents: "none",
  },
  heading: {
    fontFamily: "Jost",
    textAlign: "center",
    fontSize: "1.5rem",
    color: "white",
  },
  slider: {
    boxSizing: "border-box",
    width: "100px",
    height: "100px",
    margin: "7px",
  },
  outer: {
    margin: "7px",

    "&:hover": {
      animation: "highlight 3s infinite",
      animationFillMode: "forwards",
    },
    tooltipBtn: {
      float: "right",
    },
    card: {
      backdropFilter: "blur(10px)",
    },
  },
}))

const StyledTooltip = withStyles({
  tooltip: {
    fontFamily: "Jost",
    fontWeight: "100",
    color: "white",
    fontSize: "1rem",
  },
})(Tooltip)

export default function SkillSlot(props) {
  const classes = useStyles()

  return (
    <div className={classes.outer}>
      <StyledTooltip
        classes={{
          tooltip: classes.tooltip,
        }}
        title={props.title}
        arrow
        enterTouchDelay={300}
        enterDelay={300}
        enterNextDelay={300}
        placement="top"
      >
        <div>
          <Card
            className={classes.card}
            style={{
              background: "none",
              boxShadow: "none",
              backdropFilter: "blur(2px)",
              border: "1px solid #404040",
            }}
          >
            <CardActionArea onClick={props.clickFunc}>
              <CardMedia
                className={classes.media}
                component="img"
                image={props.image}
              />
            </CardActionArea>
          </Card>
        </div>
      </StyledTooltip>
    </div>
  )
}
