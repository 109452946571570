import React, { useState, useRef, useEffect } from "react"
import { makeStyles } from "@material-ui/core/styles"
import {
  Card,
  CardMedia,
  Button,
  withStyles,
  Typography,
  Grid,
  Paper,
  Avatar,
} from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  root: {
    width: "80%",
    background: "transparent",
    backdropFilter: "blur(3px)",
    boxShadow: "none",
    border: "1px solid #404040",
    padding: "1rem",
  },
  avatar: {
    width: "85px",
    height: "85px",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "20px",
    },
  },
  title: {
    fontFamily: "Fira Code",
    fontSize: "1rem",
    color: "white",
  },
  bodyText: {
    fontFamily: "Jost",
    fontSize: "1.2rem",
    color: "white",
  },
  author: {
    color: "white",
    fontFamily: 'Jost'
  },
  authorLink: {
    textDecoration: "none",
    color: "white",
    fontFamily: 'Jost'
  },
}))
export default function Testimonial(props) {
  const classes = useStyles()
  return (
    <Paper className={classes.root}>
      <Grid
        container
        justify="center"
        alignItems="center"
        direction={props.direction}
      >
        <Grid item>
          <Avatar
            alt={props.author}
            src={props.img}
            className={classes.avatar}
          />
        </Grid>
        <Grid item md={1} />
        <Grid item md={7}>
          <Typography className={classes.title}>{props.title}</Typography>
          {props.title && <hr style={{color: 'rgba(0,0,0,0.1)'}} />}
          <Typography className={classes.bodyText}>{props.bodyText}</Typography>
          <br />
          <Typography className={classes.author}>
            {props.author && `${props.author} - `}
            <a
              className={classes.authorLink}
              href={props.authorLink}
              target="__blank"
              rel="noopener noreferrer"
            >
              {props.authorCompany}
            </a>
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  )
}
