import React, { useState } from "react"
import { makeStyles } from "@material-ui/core/styles"
import {
  Typography,
  useMediaQuery,
  Grid,
  Tabs,
  Tab,
  withStyles,
} from "@material-ui/core"
import Fader from "react-fader"
import Helmet from 'react-helmet'
import Layout from "../components/layout"
import SkillSlot from "../components/skillslot"
import Testimonial from "../components/testimonial"
import BlogSlider from "../components/blogslider"

import AWS from "../components/assets/amazon/aws.svg"
import S3 from "../components/assets/amazon/s3.svg"
import Lambda from "../components/assets/amazon/lambda.svg"
import Route53 from "../components/assets/amazon/route53.svg"
import Cloudfront from "../components/assets/amazon/cloudfront.svg"
import DynamoDB from "../components/assets/amazon/dynamo.svg"
import APIGateway from "../components/assets/amazon/apigateway.svg"
import SES from "../components/assets/amazon/ses.svg"
import CertManager from "../components/assets/amazon/certmanager.svg"

import ReactJS from "../components/assets/ReactJS.png"
import MaterialUI from "../components/assets/MUI.png"
import ReactHelmet from "../components/assets/helmet.png"

import Node from "../components/assets/node.png"
import NPM from "../components/assets/npm.png"
import Express from "../components/assets/express.png"

import Javascript from "../components/assets/javascript.png"
import ES6 from "../components/assets/es6.png"
import JSONImg from "../components/assets/json.png"

import Python from "../components/assets/python.png"
import Microservice from "../components/assets/microservice.png"
import Flask from "../components/assets/flask.png"

import Jest from "../components/assets/jest.png"

import Postgres from "../components/assets/postgres.png"
import SQL from "../components/assets/database.png"
import Mongo from "../components/assets/mongo.png"

import Git from "../components/assets/Git.png"
import Github from "../components/assets/octocat.png"

import HTML5 from "../components/assets/html5.png"
import Accessibility from "../components/assets/accessibility.png"
import SEO from "../components/assets/seo.svg"

import CSS3 from "../components/assets/css3.png"
import Responsive from "../components/assets/responsive.svg"
import Sass from "../components/assets/sass.png"

import Bond from "../components/assets/testimonials/bondglyph.svg"
import JSM from "../components/assets/testimonials/jsm.png"
import Avery from "../components/assets/testimonials/Dawn.jpg"

import API from "../components/assets/certifications/api.png"
import ES62 from "../components/assets/certifications/es62.png"
import Responsive2 from "../components/assets/certifications/responsive.svg"
import Harvard from "../components/assets/certifications/harvard.png"

const useStyles = makeStyles(theme => ({
  header: {
    fontSize: "2.5rem",
    textAlign: "center",
    color: "white",
    fontFamily: "Jost",
  },
  subHeader: {
    fontSize: "1.8rem",
    textAlign: "center",
    color: "white",
    fontFamily: "Jost",
  },
  spacer: {
    height: "5vh",
  },
}))

const StyledTab = withStyles({
  wrapper: {
    fontFamily: "Fira Code",
    fontSize: "1rem",
    color: "white",
  },
})(Tab)

function MenuTabs() {
  const [tabIndex, setTabIndex] = useState(0)
  const isPhone = useMediaQuery("(max-width: 600px)")
  function handleChange(event, newValue) {
    setTabIndex(newValue)
  }
  return (
    <>
    <Tabs
    variant={isPhone ? "fullWidth" : "standard"}
    value={tabIndex}
    onChange={handleChange}
    centered
    > 
      <StyledTab label="Testimonials" />
      <StyledTab label="Certifications" />
    </Tabs>
    {tabIndex === 0 ? (<Testimonials/>) : (<Certifications/>)}
    </>
  )
}

function Certifications() {
  const classes = useStyles()
  const isPhone = useMediaQuery("(max-width: 600px)")
  const isLaptop = useMediaQuery("(min-width: 601px, max-width: 1400px)")
  let gridSize = 10
  isPhone ? (gridSize = 10) : isLaptop ? (gridSize = 8) : (gridSize = 6)
  return (
    <>
      <Grid container justify="center">
        <Grid item sm={gridSize}>
          <Grid container justify="center">
            <Testimonial
              img={API}
              title="FreeCodeCamp - APIs and Microservices"
              bodyText="This certficiation involves developing APIs using Node.js and Express, along with MongoDB for
              database functionality. Around 300 hours of coursework were required to obtain the certificate."
              authorCompany="Click here to see the certificate."
              authorLink="https://www.freecodecamp.org/certification/jaack/apis-and-microservices"
            />
            <Testimonial
              img={ES62}
              title="FreeCodeCamp - JavaScript Algorithms and Data Structures "
              bodyText="This certification involves building JavaScript algorithms and programs to solve problems. 
              Topics include Regular Expressions, ES6, and object oriented programming. Around 300 hours of coursework were needed."
              authorCompany="Click here to see the certificate."
              authorLink="https://www.freecodecamp.org/certification/jaack/javascript-algorithms-and-data-structures"
            />
            <Testimonial
              img={Responsive2}
              title="FreeCodeCamp - Responsive Web Design "
              bodyText="This certification has you building responsive websites using HTML and CSS, along with frameworks such as Bootstrap if you choose.
              Around 300 hours of coursework were required to earn the certificate."
              authorCompany="Click here to see the certificate."
              authorLink="https://www.freecodecamp.org/certification/jaack/responsive-web-design"
            />
            <Testimonial
              img={Harvard}
              title="CS50's Introduction to Computer Science"
              bodyText="A world famous course from Harvard University, this course serves as a brilliant foundation
              to the world of computer science, covering topics such as C, Python, JavaScript, SQL, HTML5 and CSS, along with the fundamentals such as
              algorithms and data structures."
              authorCompany="Click here to see the certificate."
              authorLink="https://certificates.cs50.io/f7204d04-548d-4391-a1ea-d637f87b4209"
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

function Testimonials(props) {
  const classes = useStyles()
  const isPhone = useMediaQuery("(max-width: 600px)")
  const isLaptop = useMediaQuery("(min-width: 601px, max-width: 1400px)")
  let gridSize = 10
  isPhone ? (gridSize = 10) : isLaptop ? (gridSize = 8) : (gridSize = 6)
  return (
    <>
      <Grid container justify="center">
        <Grid item sm={gridSize}>
          <Grid container justify="center">
            <Testimonial
              img={Bond}
              bodyText="Jack was great to work with when building my website, he did a
            fantastic design throughout. I was very pleased and he was easy to
            communicate with, highly recommend his services."
              author="Chase Bond"
              authorCompany="Bond Carpentry & Decorating"
              authorLink="https://www.bondcarpentryanddecorating.co.uk/"
            />
            <Testimonial
              img={JSM}
              bodyText="Jack designed my website for me and provided a one to one personal service.
                Very approchable and excellent communication skills.
                I would recommend Jack to anyone wanting to set up a new website."
              author="James Mack"
              authorCompany="JSM Services"
              authorLink="https://jsm-services.co.uk/"
            />
            <Testimonial
              img={Avery}
              bodyText="We first met Jack in summer 2019 and from the outset could tell how talented he was. 
              He offered to help our son Aston and our organisation with a website.
              I'm a total novice, so everything was led by Jack, who I must add had some amazing ideas.
              He asked us for information on everything we do, and using his talent has built us a website that will
              not only enable us to raise autism awareness, but also allow us to gain exposure and captivate audiences that may need our assistance.
              Jack is both professional and talented at what he does, and we would all recommend his expertise to help your business or organisation grow. "
              author="Dawn Avery"
              authorCompany="Team Avery Essex"
              authorLink="https://teamaveryessex.com"
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

function SkillShowcase(props) {
  const [amazon, setAmazon] = useState(false)
  const [react, setReact] = useState(false)
  const [node, setNode] = useState(false)
  const [js, setJs] = useState(false)
  const [python, setPython] = useState(false)

  const [db, setDb] = useState(false)
  const [git, setGit] = useState(false)
  const [html5, setHTML5] = useState(false)
  const [css3, setCSS3] = useState(false)

  const classes = useStyles()
  const isPhone = useMediaQuery("(max-width: 600px)")
  const isLaptop = useMediaQuery("(min-width: 601px, max-width: 1400px)")
  let gridSize = 10

  isPhone ? (gridSize = 10) : isLaptop ? (gridSize = 8) : (gridSize = 6)

  return (
    <Fader>
      <div className={classes.spacer} />
      <Grid container justify="center">
        <Grid item sm={gridSize}>
          <Grid container justify="center">
            {amazon === false &&
              react === false &&
              node === false &&
              js === false &&
              python === false &&
              db === false &&
              git === false &&
              html5 === false &&
              css3 === false && (
                <>
                  <SkillSlot
                    clickFunc={() => setAmazon(!amazon)}
                    image={AWS}
                    title={
                      "Amazon Web Services - A collection of Cloud services for any use you could imagine. Click the icon to see more AWS skills."
                    }
                  />
                  <SkillSlot
                    clickFunc={() => setReact(!react)}
                    image={ReactJS}
                    title={
                      "React.js - JavaScript framework for creating Single Page Web Applications."
                    }
                  />
                  <SkillSlot
                    clickFunc={() => setNode(!node)}
                    image={Node}
                    title={
                      "Node.js - A runtime environment allowing execution of server-side JavaScript."
                    }
                  />
                  <SkillSlot
                    clickFunc={() => setJs(!js)}
                    image={Javascript}
                    title={
                      "JavaScript - Language of the web. Used just about everywhere."
                    }
                  />
                  <SkillSlot
                    clickFunc={() => setPython(!python)}
                    image={Python}
                    title={
                      "Python - Powerful language that can be used for just about anything, from machine learning to desktop apps."
                    }
                  />
                  {/* INSERT UNIT TESTING HERE */}
                  <SkillSlot
                    clickFunc={() => setDb(!db)}
                    image={SQL}
                    title={
                      "Databases - Both SQL and NoSQL databases are used across my tech stack."
                    }
                  />
                  <SkillSlot
                    clickFunc={() => setGit(!git)}
                    image={Git}
                    title={
                      "Git - Legendary version control software used by almost every developer."
                    }
                  />
                  <SkillSlot
                    clickFunc={() => setHTML5(!html5)}
                    image={HTML5}
                    title={
                      "HTML5 - A markup language used to build the skeleton of a webpage."
                    }
                  />
                  <SkillSlot
                    clickFunc={() => setCSS3(!css3)}
                    image={CSS3}
                    title={
                      "CSS3 - Styling language for webpages, with endless options for customisation."
                    }
                  />
                </>
              )}
          </Grid>
        </Grid>
      </Grid>
      <>
        {amazon && (
          <Grid container justify="center">
            <Grid item sm={gridSize}>
              <Grid container justify="center">
                <SkillSlot
                  clickFunc={() => setAmazon(!amazon)}
                  image={AWS}
                  title={
                    "Amazon Web Services - A collection of Cloud services for any use you could imagine. Click the icon to see more AWS skills."
                  }
                />
                <SkillSlot
                  image={S3}
                  title={
                    "AWS S3 - Cloud Storage solution with a multitude of options. Can also be used to host static websites."
                  }
                />
                <SkillSlot
                  image={Lambda}
                  title={
                    "AWS Lambda - Serverless Environment allowing you to run code at scale without a server."
                  }
                />
                <SkillSlot
                  image={Route53}
                  title={
                    "AWS Route 53 - Domain Management Service allowing you to register and control your web domains."
                  }
                />
                <SkillSlot
                  image={Cloudfront}
                  title={
                    "AWS Cloudfront - A content delivery network for lightning fast website speeds."
                  }
                />
                <SkillSlot
                  image={DynamoDB}
                  title={
                    "AWS DynamoDB - A NoSQL database with millisecond levels of performance."
                  }
                />
                <SkillSlot
                  image={APIGateway}
                  title={
                    "API Gateway - A service allowing you to create manageable, scaleable APIs."
                  }
                />
                <SkillSlot
                  image={SES}
                  title={
                    "Amazon SES - Facilitates the sending and receiving of e-mails across domains."
                  }
                />
                <SkillSlot
                  image={CertManager}
                  title={
                    "Amazon Certificate Manager - Service allowing you to create and deploy SSL certificates to your domains and services."
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        )}
      </>
      {react && (
        <Grid container justify="center">
          <Grid item sm={gridSize}>
            <Grid container justify="center">
              <SkillSlot
                clickFunc={() => setReact(!react)}
                image={ReactJS}
                title={
                  "React.js - JavaScript framework for creating Single Page Web Applications."
                }
              />
              <SkillSlot
                image={MaterialUI}
                title={
                  "Material UI - Useful component framework for React, with the ability to override all default styles."
                }
              />
              <SkillSlot
                image={ReactHelmet}
                title={
                  "React Helmet - Special React component allowing you to control the headers of a page, greatly increasing your app's SEO capabilities."
                }
              />
            </Grid>
          </Grid>
        </Grid>
      )}
      {node && (
        <Grid container justify="center">
          <Grid item sm={gridSize}>
            <Grid container justify="center">
              <SkillSlot
                clickFunc={() => setNode(!node)}
                image={Node}
                title={
                  "Node.js - A runtime environment allowing execution of server-side JavaScript."
                }
              />
              <SkillSlot
                image={NPM}
                title={
                  "NPM - Well known package manager for Node. Allows easy installation and management of a wide range of packages.  "
                }
              />
              <SkillSlot
                image={Express}
                title={
                  "Express - Web framework for Node. Has a large amount of middleware add-ons for many use cases, such as authentication and routing."
                }
              />
            </Grid>
          </Grid>
        </Grid>
      )}
      {js && (
        <Grid container justify="center">
          <Grid item sm={gridSize}>
            <Grid container justify="center">
              <SkillSlot
                clickFunc={() => setJs(!js)}
                image={Javascript}
                title={
                  "JavaScript - Language of the web. Used just about everywhere."
                }
              />
              <SkillSlot
                image={ES6}
                title={` ES6 - "Modern" JavaScript. Introduces a lot of new features aimed at expanding the functionality and usability of JavaScript.`}
              />
              <SkillSlot
                image={JSONImg}
                title={
                  "JSON - A data format mainly used for communicating with APIs. "
                }
              />
            </Grid>
          </Grid>
        </Grid>
      )}
      {python && (
        <Grid container justify="center">
          <Grid item sm={gridSize}>
            <Grid container justify="center">
              <SkillSlot
                clickFunc={() => setPython(!python)}
                image={Python}
                title={
                  "Python - Powerful language that can be used for just about anything, from machine learning to desktop apps."
                }
              />
              <SkillSlot
                image={Microservice}
                title={` Microservice Architecture - A style of coding that utilizes many small services linked to create a large end product. `}
              />
              <SkillSlot
                image={Flask}
                title={
                  "Flask - A web framework for Python that includes a server-side templating language."
                }
              />
            </Grid>
          </Grid>
        </Grid>
      )}
      {/* RESERVED FOR TDD */}
      {db && (
        <Grid container justify="center">
          <Grid item sm={gridSize}>
            <Grid container justify="center">
              <SkillSlot
                clickFunc={() => setDb(!db)}
                image={SQL}
                title={
                  "Databases - Both SQL and NoSQL databases are used across my tech stack."
                }
              />
              <SkillSlot
                image={Postgres}
                title={`PostgreSQL - A popular implementation of SQL that allows for complicated and versatile data storage.`}
              />
              <SkillSlot
                image={Mongo}
                title={
                  "MongoDB - A NoSQL database known for its similarity to JavaScript objects. Often used in the cloud."
                }
              />
            </Grid>
          </Grid>
        </Grid>
      )}
      {git && (
        <Grid container justify="center">
          <Grid item sm={gridSize}>
            <Grid container justify="center">
              <SkillSlot
                clickFunc={() => setGit(!git)}
                image={Git}
                title={
                  "Git - Legendary version control software used by almost every developer."
                }
              />
              <SkillSlot
                image={Github}
                title={`The home of open source. You can check out my profile at the top of the page.`}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
      {html5 && (
        <Grid container justify="center">
          <Grid item sm={gridSize}>
            <Grid container justify="center">
              <SkillSlot
                clickFunc={() => setHTML5(!html5)}
                image={HTML5}
                title={
                  "HTML5 - A markup language used to build the skeleton of a webpage."
                }
              />
              <SkillSlot
                image={Accessibility}
                title={`Accessibility - My websites have semantic markup, to cater for as many people as possible.`}
              />
              <SkillSlot
                image={SEO}
                title={`Search Engine Optimisation (SEO) - SEO is extremely important for businesses looking to get their name out there. Good markup plays a huge role in this.`}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
      {css3 && (
        <Grid container justify="center">
          <Grid item sm={gridSize}>
            <Grid container justify="center">
              <SkillSlot
                clickFunc={() => setCSS3(!css3)}
                image={CSS3}
                title={
                  "CSS3 - Styling language for webpages, with endless options for customisation."
                }
              />
              <SkillSlot
                image={Responsive}
                title={`Responsive Design - Websites look and feel native, on any screen size.`}
              />
              <SkillSlot
                image={Sass}
                title={`Sass - A CSS extension language aimed at making it work better for large scale projects. `}
              />
            </Grid>
          </Grid>
        </Grid>
      )}
    </Fader>
  )
}

export default function Home() {
  const classes = useStyles()
  return (
    <>
      <Layout tabIndex={0}>
        <Typography className={classes.header}>
          Hi, I'm Jack.
        </Typography>
        <Typography className={classes.subHeader}>
          A web developer with a passion for cloud based solutions.
        </Typography>
        <SkillShowcase />
        <div className={classes.spacer} />
        <MenuTabs/>
        <BlogSlider/>
      </Layout>
    </>
  )
}
