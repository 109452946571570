import React, {useEffect} from 'react'
import { makeStyles } from "@material-ui/core/styles"
import {Box, Grid, Container, useMediaQuery,} from '@material-ui/core'
import Helmet from 'react-helmet'

const useStyles = makeStyles(theme => ({
    root: {
    },
    blogTitle: {
        fontFamily: 'Jost',
        fontSize: '2rem',
        textAlign: 'center',
        color: 'white'
    }
  }))
export default function BlogSlider() {
    const classes = useStyles()
    const isPhone = useMediaQuery('(max-width: 600px)', { noSsr: true})
    const isLaptop = useMediaQuery("(min-width: 601px, max-width: 1400px)")
    let gridSize = 10
    isPhone ? (gridSize = 10) : isLaptop ? (gridSize = 8) : (gridSize = 6)
    let perLine = null
    let limit = null
    useEffect(() => {
        function waitForElement2(){
            if(typeof isPhone !== 'undefined'){
                if (isPhone) {
                    perLine = 1
                    limit = 3
                } else {
                    perLine = 2
                    limit = 4
                }
            }
            else{
                setTimeout(waitForElement, 250);
            }
        }
        waitForElement2()
        function waitForElement(){
            if(typeof window.MediumWidget !== "undefined" && typeof isPhone !== 'undefined'){
                    window.MediumWidget.Init({renderTo: '#medium-widget', params: {"resource":"https://medium.com/@jaackdev","postsPerLine":perLine,"limit":limit,"picture":"big","fields":["description","publishAt"],"ratio":"landscape"}})
            }
            else{
                setTimeout(waitForElement, 250);
            }
        }
        waitForElement()
    },[])
    return(
        <>
            <Grid container justify="center" alignItems="center">
                <Grid item sm={gridSize}>
                    <div style={{height: '7vh'}}/>
                    <div className={classes.blogTitle}>Latest Medium Posts</div>
                    <div style={{height: '5vh'}}/>
                    <div id="medium-widget" className={classes.root}></div>
                </Grid>
            </Grid>
        </>

    )
}

